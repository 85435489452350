import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Download from "../../../assets/icons/DownloadIcon.svg";
import { productTypeImageObj } from "../../../helpers/enums";
import { InsurerLogoMap } from "../../../helpers/InsurerLogoMap";
import { Link } from "react-router-dom";
import BookingCoin from "../../../assets/Lottie/bookingCoin.json";
import Lottie from "react-lottie";

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: BookingCoin,
  resizeMode: "center",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function BookingCard({ bookingList, handleOpenModal }) {
  return (
    <>
      <Box>
        <Box display="flex" flexWrap="wrap" gap="25px">
          {bookingList.map((policy) => (
            <Box
              key={policy.id}
              sx={{
                marginBottom: "16px",
                display: "flex",
                paddingBottom: "0px",
                width: "calc(50% - 13px)",
                flexDirection: "column",
                flexWrap: "wrap",
                boxSizing: "border-box",
              }}
            >
              <Card
                sx={{
                  borderRadius: "12px",
                  height: "220px",
                  border: "0px solid transparent",
                  position: "relative",
                  cursor: "pointer",
                  wordWrap: "break-word",
                }}
                onClick={() => handleOpenModal(policy)}
              >
                <CardContent>
                  <Typography
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "21px ",
                        display: "flex",
                        alignItems: "center ",
                      }}
                    >
                      <div
                        className="icon"
                        style={{
                          backgroundColor: "#FAFAFA",
                          borderRadius: "10px ",
                          margin: "13px ",
                          width: "55px",
                          height: "55px ",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                          paddingLeft: "15px",
                        }}
                      >
                        {productTypeImageObj[policy.product]}
                      </div>
                      <span
                        style={{
                          color: "grey",
                          fontSize: "12px",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {policy.registrationNumber} <br />
                        {policy.make} {policy.model}
                      </span>
                    </Typography>
                    <Typography component="div">
                      {InsurerLogoMap[policy?.insurerId]?.Logo ? (
                        <img
                          src={InsurerLogoMap[policy?.insurerId]?.Logo}
                          alt="Insurer Logo"
                        />
                      ) : (
                        "Logo not found"
                      )}
                    </Typography>

                    <Typography>
                      <div
                        className="coin-container"
                        style={{ lineHeight: "25px" }}
                      >
                        <div
                          style={{
                            gap: "10px",

                            display: "flex",
                            alignItems: "center",
                            borderRadius: "17px",
                            border: "1px solid",
                            borderColor:
                              policy.status === "BOOKED"
                                ? "#32C053"
                                : "#FF0000",
                            minWidth: "62px",
                            color:
                              policy.status === "BOOKED"
                                ? "#32C053"
                                : "#FF0000",
                            height: "20px",
                            // padding: "4px 8px",
                            justifyContent: "space-between",
                            paddingRight: "8px",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "24px",
                              backgroundColor:
                                policy.status === "BOOKED"
                                  ? "#32C053"
                                  : "#FF0000",
                              borderRadius: "50%",
                              height: "24px",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                transform: "translate(-28%, -27%)",
                              }}
                            >
                              <Lottie
                                options={defaultLottieOptions}
                                height={52}
                                width={52}
                              />
                            </div>
                          </div>
                          <p>{policy.activityPoint ?? "0"}</p>
                        </div>
                      </div>
                      <Typography
                        sx={{
                          fontSize: "12px ",
                          lineHeight: "18px ",
                          fontWeight: "400",
                          color: "#808080",
                        }}
                        component="span"
                      >
                        {" "}
                        Partner
                        <br />
                        <Typography
                          component="h3"
                          sx={{
                            fontSize: "12px ",
                            color: "black",
                            fontWeight: "400",
                            lineHeight: "18px",
                          }}
                        >
                          {policy.partnerId}
                        </Typography>
                      </Typography>
                    </Typography>
                  </Typography>

                  {/* Status Badge */}
                  <Typography component="div" sx={{ marginTop: "3px" }}>
                    <Typography
                      component="span"
                      sx={{
                        border: "0.5px solid #D9D9D9",
                        width: "490px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></Typography>
                    <Typography
                      component="button"
                      sx={{
                        width: "132px ",
                        height: "20px",
                        padding: "2px 8px",
                        backgroundColor:
                          policy.status === "BOOKED" ? "#28A745" : "#FF5A5F",
                        borderRadius: "6px ",
                        fontSize: "10px",
                        fontWeight: "600",
                        color: "white",
                        textAlign: "center ",
                        position: "absolute",
                        right: "183px ",
                        bottom: "105px ",
                      }}
                    >
                      {policy.status}
                    </Typography>
                  </Typography>

                  {/* Customer, Booking ID, Premium */}
                  <Typography
                    component="div"
                    sx={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center ",
                    }}
                  >
                    <Typography
                      component="h3"
                      sx={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        color: "#808080",
                      }}
                    >
                      Customer <br />
                      <Typography
                        sx={{
                          fontSize: "14px ",
                          color: "black",
                          lineHeight: "21px ",
                        }}
                        component="span"
                      >
                        {policy.customerName}
                      </Typography>
                    </Typography>
                    <Typography
                      component="h3"
                      sx={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        color: "#808080",
                      }}
                    >
                      Booking ID <br />
                      <Typography
                        sx={{
                          fontSize: "14px ",
                          color: "black",
                          lineHeight: "21px ",
                        }}
                        component="span"
                      >
                        {policy.bookingId}
                      </Typography>
                    </Typography>

                    <Typography
                      component="h3"
                      sx={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        color: "#808080",
                      }}
                    >
                      Premium <br />
                      <Typography
                        sx={{
                          fontSize: "14px ",
                          color: "black",
                          lineHeight: "21px ",
                        }}
                        component="span"
                      >
                        {policy.premium}
                      </Typography>
                    </Typography>
                  </Typography>
                </CardContent>
                <Typography
                  component="div"
                  sx={{
                    width: "100%",
                    height: "44px",
                    padding: " 8px 0px 8px 0px",
                    backgroundColor: "#e8f3fc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      textAlign: "center",
                      lineHeight: "18px",
                      fontSize: "12px ",
                      fontWeight: "600",
                      color: "#0691FC",
                      display: "flex",
                      alignItems: "center ",
                      justifyContent: "center ",
                      gap: "6px ",
                      cursor: "pointer ",
                    }}
                  >
                    <img src={Download} alt="download" />
                    <Link
                      to={policy.policyCopyUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      download // This will prompt the download if supported by the browser
                    >
                      Download PDF
                    </Link>
                  </Typography>
                </Typography>
              </Card>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}
