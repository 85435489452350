import React, { useState } from "react";
import Heading from "../../components/generic/Heading/Heading";
import { Button, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { submitInspectionReportAPI } from "../../store/action/quote";
import DialogBox from "../../components/generic/DialogBox";
import CutomeModalNoQuote from "../../components/Form/CutomeModalNoQuote";
import DropDown from "../../components/generic/DropDown/DropDown";
import { ReactComponent as AttachIcon } from "../../assets/icons/attachIcon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/bin.svg";
import QuoteCardBox from "../../components/QuoteComponent/QuoteCardBox";
import Upload from "../../assets/images/RaiseRequest/upload.svg";

const InspectionReportPending = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.quote.quoteDetails);
  const quoteState = data?.data?.quoteState;
  // Todo:
  // need to optimize submitInspectionReportAPI function to handle setOpenSuccess and setDeclinedata
  // eslint-disable-next-line no-unused-vars
  const [openSuccess, setOpenSuccess] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [declinedata, setDeclinedata] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [reason, setReason] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [file, setfile] = useState(null);
  const [inspectionState, setInspectionState] = useState("");

  const handleReason = (selectedReason) => {
    setReason(selectedReason);
  };

  const handleAccept = () => {
    setInspectionState("APPROVED");
  };

  const handleReject = () => {
    setIsValid(true);
    setInspectionState("REJECTED");
  };

  const handleSubmitReport = () => {
    dispatch(
      submitInspectionReportAPI(
        {
          file,
          inspectionState,
          reason,
          ticketId: data?.data?.header?.ticketId,
        },
        setOpenSuccess,
        setDeclinedata,
      ),
    );
  };
  return (
    <div
      style={{
        width: "100%",
        padding: "0 30px 0 0",
      }}
    >
      <Heading text="Shared Quotes" type="withborder" />

      <div style={{ marginTop: "16px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          {data?.data?.offlineQuotes?.map((i) => (
            <QuoteCardBox
              quoteData={i}
              key={i?.quoteRequestId}
              quoteState={quoteState}
            />
          ))}
        </div>

        {confirm && (
          <DialogBox
            width={500}
            open={confirm}
            setOpen={setConfirm}
            children={
              <CutomeModalNoQuote
                setOpen={setConfirm}
                title={"Are you sure?"}
                // subTitle={
                //   "You will be notified once partner accept/reject the quotes"
                // }
                confirmButton={"Reject quote"}
                cancelButton={"Cancel"}
                onClick={() => {
                  handleReject();
                  setConfirm(false);
                }}
              />
            }
          />
        )}

        <div style={{ marginTop: "16px" }}>
          <Heading text="Inspection Details" type="withborder" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              marginTop: "16px",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: "12px",
                background: "var(--White, #FFF)",
                boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <h1
                style={{
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#000",
                }}
              >
                Customer Details
              </h1>
              <div
                style={{
                  backgroundColor: "#FAFAFA",
                  padding: "12px",
                  borderRadius: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {data?.data?.customerDetails?.map((i) => (
                    <div
                      key={`customer-details-${i?.key}`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <h3 style={{ fontSize: "12px", fontWeight: 400 }}>
                        {i?.key}
                      </h3>
                      {i?.key && (
                        <p style={{ fontSize: "12px", fontWeight: 400 }}>
                          {i?.value}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* <Third/> */}

              <div
                style={{
                  textAlign: "center",
                  background: "#E8F3FC",
                  padding: "12px",
                  borderRadius: "8px",
                }}
              >
                <h2
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#000",
                  }}
                >
                  Inspection Report Pending!
                </h2>
                <h3 style={{ fontSize: "12px", fontWeight: 400 }}>
                  Please Upload The Inspection Report Below
                </h3>
                <div
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      boxShadow: "#E6E6E6",
                      padding: "8px 12px",
                      height: 42,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "4px",
                          display: "flex",
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "13px",
                            fontWeight: 500,
                            color: "#000",
                          }}
                        >
                          Inspection Report *{" "}
                        </h3>
                      </div>
                    </div>
                    {file ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <AttachIcon />
                        <div style={{ display: "flex", width: "200px" }}>
                          <Typography
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              // width: "10px",
                            }}
                            variant="p"
                          >
                            {file?.name}
                          </Typography>
                        </div>
                        <RemoveIcon
                          sx={{ color: "red" }}
                          onClick={() => setfile(null)}
                          cursor={"pointer"}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <img src={Upload} alt="view" target="_blank" />
                          <h2
                            style={{
                              color: "#0691FC",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            <input
                              type="file"
                              onChange={(e) => {
                                setfile(e.target.files?.[0]);
                              }}
                              name="uploadfile"
                              id="img"
                              style={{ display: "none" }}
                            />
                            <label htmlFor="img" style={{ cursor: "pointer" }}>
                              Upload Inspection Report
                            </label>
                          </h2>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8x",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      boxShadow: "#E6E6E6",
                      padding: "8px 12px",
                      height: 42,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 12,
                        marginTop: 4,
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 12, color: "#000", fontWeight: 500 }}
                      >
                        Update Report Status
                      </Typography>
                    </div>

                    {isValid ? (
                      <Box display="flex" flexDirection="row" gap={1}>
                        {(inspectionState === "APPROVED" ||
                          inspectionState === "") && (
                          <Button
                            onClick={() => handleAccept()}
                            startIcon={<DoneIcon />}
                            sx={{
                              color: "#23963D",
                              fontSize: 12,
                              textTransform: "capitalize",
                              backgroundColor:
                                inspectionState === "APPROVED"
                                  ? "#E6F4EA"
                                  : "#fff",
                            }}
                          >
                            Approved
                          </Button>
                        )}
                        {(inspectionState === "REJECTED" ||
                          inspectionState === "") && (
                          <Button
                            onClick={() => setIsValid(false)}
                            startIcon={<CloseIcon />}
                            sx={{
                              color: "#DF0000",
                              fontSize: 12,
                              textTransform: "capitalize",
                              backgroundColor:
                                inspectionState === "REJECTED"
                                  ? "#FFEBEB"
                                  : "#fff",
                            }}
                          >
                            Not Approved
                          </Button>
                        )}
                        {inspectionState !== "" && (
                          <Button onClick={() => setInspectionState("")}>
                            edit
                          </Button>
                        )}
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems={"center"}
                      >
                        <Box flexGrow={1} />
                        <DropDown
                          value={reason}
                          onChange={(e) => handleReason(e.target.value)}
                          placeholder="Select reason"
                          data={["Reason 1", "Reason 2", "Reason 3"]}
                          size="small"
                          style={{
                            fontSize: 14,
                            border: "1px solid #8B8B8B",
                            width: "100%",
                            background: "#fff",
                            height: 34,
                            borderRadius: 8,
                            padding: "0 12px",
                          }}
                        />
                        <Button
                          variant="text"
                          onClick={() => handleReject()}
                          disabled={!reason}
                          sx={{
                            color: "#DF0000",
                            mx: 2,
                            fontSize: 12,
                            minWidth: "unset",
                            textTransform: "capitalize",
                          }}
                        >
                          SUBMIT
                        </Button>
                      </Box>
                    )}
                  </div>
                  <Button
                    onClick={handleSubmitReport}
                    style={{
                      backgroundColor: "#0691FC",
                      textTransform: "none",
                      color: "#fff",
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontWeight: 600,
                      width: "160px",
                      height: "45px",
                      marginTop: 16,
                      alignSelf: "center",
                    }}
                  >
                    SUBMIT REPORT{" "}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default InspectionReportPending;
