import React from "react";
import Header from "../../components/generic/Header/Header";
import { Box, CircularProgress, Stack } from "@mui/material";
import Search from "../../components/generic/Search/Search";
import Flatpickr from "react-flatpickr";
import DropDown from "../../components/generic/DropDown/DropDown";
import { useDispatch, useSelector } from "react-redux";
import Paginations from "../../components/generic/Pagination/Pagination.jsx";
import "./index.css";
import BookingCard from "./BookingCard/BookingCard";
import { useCallback, useEffect, useState } from "react";
import CustomTabing from "../../components/generic/Tabing/CustomTabing.jsx";
import {
  salesBookingListAction,
  salesBookingModalAction,
} from "../../store/action/salesBooking";
import { formatDate, debounce } from "../../utils/utils";
import { fetchParterIdsList } from "../../store/action/auth.js";
import BookingModal from "./BookingModal/BookingModal.jsx";

const BookingPage = () => {
  let searchParams = {};
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [bookingId, setBookingId] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // Manages modal open/close
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  // sales bookingList
  const { bookingList, bookingBucket, pagination, loading, isModalLoading, modalData } =
    useSelector((state) => state?.salesBooking);

  const {
    headerData,
    vehicleDetails,
    policyDetails,
    premiumDetails,
    businessType,
    policyCopyUrl,
  } = modalData || {};

  useEffect(() => {
    if (bookingId) {
      dispatch(salesBookingModalAction(bookingId));
    }
  }, [bookingId]);

  const { partnerIds } = useSelector((state) => state.auth);

  // Debounce the search input
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      if (searchValue.length >= 3) {
        setDebouncedSearch(searchValue);
      } else {
        setDebouncedSearch(""); // Clear search if less than 3 characters
      }
    }, 500),
    [],
  );
  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [currentBucket, setcurrentBucket] = useState();
  const [debouncedSearch, setDebouncedSearch] = useState("");
  //  SEARCH LIST
  const handleSearch = (evt) => {
    const searchValue = evt.target.value;
    setSearch(searchValue);
    debounceSearch(searchValue);
  };
  const handleDateChange = (date) => {
    if (date && date.length > 0) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  const handleTabChange = (newValue) => {
    if (!loading) {
      setcurrentBucket(newValue);
      setCurrentPage(1); // Reset to page 1 when bucket changes
    }
  };

  // Modal Handlers
  const handleOpenModal = (booking) => {
    setBookingId(booking?.bookingId); // Set the clicked booking
    setIsOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setBookingId(null); // Clear the selected booking
    setIsOpen(false); // Close the modal
  };

  // page change

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (!partnerIds) {
      dispatch(fetchParterIdsList());
    }
  }, [partnerIds]);

  // Trigger API call to fetch the booking list for the selected bucket
  useEffect(() => {
    if (currentBucket) {
      searchParams["curBucket"] = currentBucket;
    }

    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (selectedPartner) {
      searchParams["partnerId"] = selectedPartner;
    }
    if (debouncedSearch) {
      searchParams["searchQuery"] = debouncedSearch;
    }

    if (filterByDate.startDate && filterByDate.endDate) {
      searchParams["startDate"] = formatDate(filterByDate.startDate);
      searchParams["endDate"] = formatDate(filterByDate.endDate);
    }

    dispatch(salesBookingListAction(searchParams));
  }, [
    currentBucket,
    currentPage,
    selectedPartner,
    filterByDate,
    debouncedSearch,
  ]);

  return (
    <div className="main">
      <Header title="Bookings" />
      <div className="booking-filters-item">
        <Search value={search} onChange={handleSearch} />
        <Stack direction="row" spacing={2} alignItems="center">
          <label htmlFor="partner-dropdown" className="label">
            Filters :
          </label>
          <DropDown
            value={selectedPartner}
            onChange={(e) => setSelectedPartner(e.target.value)}
            width={175}
            placeholder="Select Partner"
            data={partnerIds}
          />
          <div className="date-picker">
            <Flatpickr
              placeholder="Date Range"
              options={{
                dateFormat: "d-m-Y",
                maxDate: new Date(),
                mode: "range",
              }}
              className="flat_picker_date"
              value={
                filterByDate.startDate && filterByDate.endDate
                  ? [filterByDate.startDate, filterByDate.endDate]
                  : ""
              }
              onChange={handleDateChange}
            />
          </div>
        </Stack>
      </div>

      <div className="custom-tabing-wrapper">
        <CustomTabing
          value={bookingBucket?.curBucket}
          handleChange={handleTabChange}
          tabList={bookingBucket?.bucketList || []}
        />
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
        <BookingCard
          bookingList={bookingList}
          handleOpenModal={handleOpenModal}
        />
        )}
        <Paginations
          currentPage={currentPage}
          totalPage={pagination?.totalPage || 1}
          handlePageChange={handlePageChange}
        />
      </div>
      {/* Render BookingModal conditionally */}
      {isOpen && (
        <BookingModal
          handleClose={handleCloseModal}
          headerData={headerData}
          vehicleDetails={vehicleDetails}
          policyDetails={policyDetails}
          premiumDetails={premiumDetails}
          policyCopyUrl={policyCopyUrl}
          businessType={businessType}
          isModalLoading={isModalLoading}
        />
      )}
    </div>
  );
};

export default BookingPage;
