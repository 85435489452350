import * as React from "react";
import Table from "@mui/joy/Table";
import Avatar from "@mui/material/Avatar";
import { AvatarColors } from "../../assets/constant";
import { Stack, Typography } from "@mui/material";

const SalesManagerBookingTable = ({ salesManagerBookingList, onRowClick }) => {
  return (
    <Table borderAxis="both" sx={{ marginTop: "20px" }}>
      <thead>
        <tr>
          <th
            style={{
              width: "23%",
              backgroundColor: "#f5f5f5",
              color: "",
            }}
          >
            REPORTEES
          </th>
          <th style={{ backgroundColor: "#f5f5f5", color: "black" }}>TW APE</th>
          <th style={{ backgroundColor: "#f5f5f5", color: "black" }}>
            CAR APE
          </th>
          <th style={{ backgroundColor: "#f5f5f5", color: "black" }}>CV APE</th>
          <th style={{ backgroundColor: "#f5f5f5", color: "black" }}>TW NOP</th>
          <th style={{ backgroundColor: "#f5f5f5", color: "black" }}>
            CAR NOP
          </th>
          <th style={{ backgroundColor: "#f5f5f5", color: "black" }}>CV NOP</th>
          <th style={{ backgroundColor: "#f5f5f5", color: "black" }}>
            ACTIVATIONS
          </th>
        </tr>
      </thead>
      <tbody>
        {salesManagerBookingList.map((data, index) => (
          <tr
            className="row-hover"
            key={index}
            onClick={() => onRowClick(data)}
          >
            <td style={{ padding: 0, position: "relative" }}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{ height: "90px", paddingLeft: "16px" }}
              >
                <Avatar
                  className="avatorEffect"
                  sx={{
                    bgcolor: AvatarColors(
                      data.employeeName || data.employeeLogo,
                    ),
                    width: "48px",
                    height: "48px",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  {data.employeeLogo}
                </Avatar>
                <Typography
                  component="span"
                  sx={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#4FE61A",
                    borderRadius: "50%",
                    position: "absolute",
                    left: "34px",
                    bottom: "21px",
                  }}
                ></Typography>
                <div>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    {data.employeeName}
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "18px",
                      color: "#0691FC",
                    }}
                  >
                    {data.employeeeId}
                  </Typography>
                </div>
              </Stack>
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {data.twAPE || "0"}
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {data.carAPE || "0"}
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {data.cvAPE || "0"}
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {data.twNOP || "0"}
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {data.carNOP || "0"}
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {data.cvNOP || "0"}
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {data.activations || "0"}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SalesManagerBookingTable;
