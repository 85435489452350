import { Grid, Typography } from "@mui/material";
import React from "react";
import Document from "../../assets/icons/Document.svg";
import Heading from "../../components/generic/Heading/Heading";

const SalesCaseClosed = () => {
  return (
    <>
      <Heading text="Case Closed" type="withborder" />
      <Grid
        sx={{
          margin: " 30px auto",
          width: "616px",
          height: "246px",
          borderRadius: "16px",
          backgroundColor: "white ",
        }}
      >
        <Typography
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <Typography component={"div"} sx={{ marginTop: "30px" }}>
            <Typography component={"span"}>
              <img
                src={Document}
                alt="Document"
                width={"67px"}
                height={"78px"}
              />
            </Typography>
          </Typography>{" "}
          <Typography component={"div"} sx={{ textAlign: "center" }}>
            <Typography
              component={"span"}
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "27px",
                color: "black",
              }}
            >
              {" "}
              Case Closed!
            </Typography>
            <br />
            <Typography
              component={"span"}
              sx={{
                fontSize: "14px",
                color: "#666666",
                lineHeight: "21px",
                fontWeight: "400",
              }}
            >
              Case Closed! Please Request New Quote
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </>
  );
};

export default SalesCaseClosed;
