// import {
//   POST_DATA_REQUEST,
//   POST_DATA_SUCCESS,
//   POST_DATA_FAILURE,
// } from "./actions";

// Initial state

const initialState = {
  loading: false,
  data: null,
  error: null,
};

// Reducer
const salesMappingRaiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "POST_DATA_REQUEST":
      return { ...state, loading: true, error: null };
    case "POST_DATA_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    case "POST_DATA_FAILURE":
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export default salesMappingRaiseReducer;
