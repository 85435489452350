import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import Heading from "../../components/generic/Heading/Heading";
import SuccessModal from "../../components/generic/Modal";
import { useDispatch, useSelector } from "react-redux";
import { EmailOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import {
  paymentLinkPendingAPI,
  uploadPolicyCopy,
  verifyPayment,
} from "../../store/action/quote";
import Addons from "../../components/QuoteComponent/Addons";
import { ReactComponent as AttachIcon } from "../../assets/icons/attachIcon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/bin.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/QuoteRequest/Upload.svg";
import { InsurerLogoMap } from "../../helpers/InsurerLogoMap";
import FormInput from "../../components/FormInput/FormInput";

const PaymentLinkPending = () => {
  const data = useSelector((state) => state.quote.quoteDetails);
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isPaymentDetailsTaken, setIsPaymentDetailsTaken] = useState(false);
  const handleReason = (selectedReason) => {
    setReason(selectedReason);
  };

  const handlePayment = (id) => {
    const data = {
      quoteId: id,
      premiumAmount: reason,
    };
    dispatch(paymentLinkPendingAPI(data, setOpenSuccess));
  };
  const [file, setfile] = useState(null);

  const handleVerifypayment = (flag) => {
    const data1 = {
      quoteId: data?.data?.header?.ticketId,
      isPaymentDone: flag,
    };
    dispatch(verifyPayment(data1, setOpenSuccess));
  };

  const handleUploadPolicyCopy = (file) => {
    const formData = new FormData();
    formData.append("quoteId", data?.data?.header?.ticketId);
    formData.append("file", file);
    dispatch(
      uploadPolicyCopy(formData, data?.data?.header?.ticketId, setOpenSuccess),
    );
  };

  useEffect(() => {
    if (data?.data?.quoteState === "PAYMENT_LINK_SHARED") {
      setOpenSuccess(true);
      setReason(data?.data?.premiumAmount);
    }
    if (data?.data?.quoteState === "PAYMENT_PROOF_TAKEN") {
      setOpenSuccess(true);
      setReason(data?.data?.premiumAmount);
      setIsPaymentDetailsTaken(true);
    }
    if (data?.data?.quoteState === "PAYMENT_DETAILS_APPROVED") {
      setOpenSuccess(true);
      setReason(data?.data?.premiumAmount);
      setIsPaymentDetailsTaken(false);
    }

    if (data?.data?.quoteState === "UPLOAD_POLICY_COPY") {
      setOpenSuccess(true);
    }
  }, [data]);
  return (
    <div
      style={{
        width: "100%",
        padding: "0 30px 0 0",
      }}
    >
      <Heading text="Shared Quotes" type="withborder" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          marginTop: "16px",
        }}
      >
        {data?.data?.offlineQuotes?.map((i, index) => (
          <div
            key={`quote-${index}`}
            style={{
              display: "flex",
              borderRadius: "8px",
              boxShadow: "0px 2px 4px 0px #0000001A",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: "100%",
                background:
                  i?.quoteStatus === "ACCEPTED" ||
                    i?.quoteStatus === "ACCEPTED_WITH_INSPECTION"
                    ? "#E2F1E6"
                    : "#fff",
                padding: "12px 16px",
                // boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              {i?.quoteStatus === "ACCEPTED" ||
                i?.quoteStatus === "ACCEPTED_WITH_INSPECTION" ? (
                <>
                  {openSuccess ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 12,
                          marginTop: 4,
                        }}
                      >
                        {i?.insurerId && InsurerLogoMap[i?.insurerId] ? (
                          <img
                            src={InsurerLogoMap[i?.insurerId]?.Logo}
                            alt="insurer"
                          />
                        ) : (
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: "#666666",
                              fontWeight: 500,
                            }}
                          >
                            {i?.insurerName}
                          </Typography>
                        )}
                        <div
                          style={{
                            backgroundColor: "#09CE1D",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: "10px",
                              fontWeight: 500,
                            }}
                          >
                            {i?.quoteStatusName}
                          </h3>
                        </div>
                      </div>
                      <SuccessModal
                        title={
                          data?.data?.quoteState === "PAYMENT_DETAILS_APPROVED"
                            ? "Payment Details Approved"
                            : "Payment Link Shared"
                        }
                        // subTitle={declineData?"Same has been notified to partner":"You will be notified once partner accept/reject the quotes"}
                        // shareButton="Okay"
                        btnDisabled
                      />
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 12,
                        }}
                      >
                        {i?.insurerId && InsurerLogoMap[i?.insurerId] ? (
                          <img
                            src={InsurerLogoMap[i?.insurerId]?.Logo}
                            alt="insurer"
                          />
                        ) : (
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: "#666666",
                              fontWeight: 500,
                            }}
                          >
                            {i?.insurerName}
                          </Typography>
                        )}
                        <div
                          style={{
                            backgroundColor: "#09CE1D",
                            padding: "4px 10px",
                            // borderRadius: "4px",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: "10px",
                              fontWeight: 500,
                            }}
                          >
                            {i?.quoteStatusName}
                          </h3>
                        </div>
                      </div>

                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems={"center"}
                      >
                        <Box flexGrow={1} />
                        <FormInput
                          value={reason}
                          type="number"
                          inputMode="decimal"
                          onChange={(e) => handleReason(e.target.value)}
                          placeholder="Enter Premium in Rs."
                          size="small"
                          style={{
                            fontSize: 14,
                          }}
                          inputProps={{
                            min: 0,
                            step: 0.01,
                          }}
                        />
                        <Button
                          variant="error"
                          onClick={() =>
                            handlePayment(data?.data?.header?.ticketId)
                          }
                          disabled={!reason}
                          sx={{
                            color: "#DF0000",
                            borderRadius: "8px",
                            mx: 2,
                          }}
                        >
                          SUBMIT
                        </Button>
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 12,
                    }}
                  >
                    {i?.insurerId && InsurerLogoMap[i?.insurerId] ? (
                      <img
                        src={InsurerLogoMap[i?.insurerId]?.Logo}
                        alt="insurer"
                      />
                    ) : (
                      <Typography
                        sx={{ fontSize: 12, color: "#666666", fontWeight: 500 }}
                      >
                        {i?.insurerName}
                      </Typography>
                    )}
                  </div>
                  <Link
                    to={i?.quoteUrl}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {" "}
                    <RemoveRedEyeOutlined />
                    <h2
                      style={{
                        color: "#666666",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      View Quote{" "}
                    </h2>
                  </Link>
                </Box>
              )}
            </div>
            {i?.addOns?.length > 0 && <Addons addOns={i?.addOns} />}
          </div>
          // <QuoteCardBox quoteData={i} quoteState={data?.data?.quoteState} />
        ))}
      </div>

      {openSuccess && data?.data?.quoteState !== "UPLOAD_POLICY_COPY" && (
        <>
          <Heading text="Payment Details" type="withborder" />
          <div
            style={{
              width: "100%",
              // height: 42,
              borderRadius: 8,
              background: "#00223C",
              padding: "12px 16px",
              marginTop: 12,
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                  color: "#ccc",
                }}
              >
                {data?.data?.quoteState === "PAYMENT_DETAILS_APPROVED"
                  ? "Amount Paid"
                  : "Amount to be paid"}
              </p>
              <p
                style={{
                  fontSize: 24,
                  fontWeight: "600",
                  color: "#fff",
                }}
              >
                Rs. {reason}
              </p>
            </div>
            <div
              style={{
                width: "100%",
                height: 1,
                background: "#56758D",
                marginBottom: 12,
                // gap: 2,
              }}
            ></div>
            <span style={{ fontSize: 12, fontWeight: "400", color: "#fff" }}>
              Payment link has been shared on below Email Id
            </span>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 8,
                marginTop: 12,
                alignItems: "center",
                marginLeft: 12,
              }}
            >
              <EmailOutlined style={{ color: "#0691FC" }} />
              <p
                style={{
                  fontSize: 12,
                  fontWeight: "400",
                  color: "#fff",
                }}
              >
                {data.data.email}
              </p>
            </div>
            <div>
              {isPaymentDetailsTaken && (
                <Box
                  backgroundColor={"#013258"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"24px"}
                  padding={"16px"}
                  borderRadius={"16px"}
                  marginTop={"24px"}
                  // sx={{ background: "#E2F1E6" }}
                  color={"white"}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: 14, color: "#fff", fontWeight: 500 }}
                    >
                      Please verify the policy document
                    </Typography>
                    <Link
                      to={data?.data?.paymentProofLink}
                      target="_blank"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        background: "#0691FC",
                        padding: "12px 24px 12px 24px",
                        borderRadius: "8px",
                        opacity: "0px",
                      }}
                    >
                      {" "}
                      <RemoveRedEyeOutlined />
                      <h2
                        style={{
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        View Policy Document
                      </h2>
                    </Link>
                  </div>
                  <Divider flexItem sx={{ background: "#fff" }} />

                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      // color="success"
                      width="122px"
                      sx={{ background: "#23963D", borderRadius: "8px" }}
                      padding="12px 36px 12px 36px"
                      onClick={() => handleVerifypayment(true)}
                      startIcon={<CheckIcon />}
                    >
                      Valid
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      width="122px"
                      sx={{ background: "DF0000", borderRadius: "8px" }}
                      padding="12px 36px 12px 36px"
                      onClick={() => handleVerifypayment(false)}
                      startIcon={<CloseIcon />}
                    >
                      Not Valid
                    </Button>
                  </div>
                </Box>
              )}
            </div>
          </div>
        </>
      )}
      {openSuccess && data?.data?.quoteState === "UPLOAD_POLICY_COPY" && (
        <>
          <Heading text="Payment Details" type="withborder" />
          <div
            style={{
              width: "100%",
              // height: 42,
              borderRadius: 16,
              background: "#00223C",
              marginTop: 12,
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
              color: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "24px",
              }}
            >
              {/* <Heading text="Payment Details" type="withborder" /> */}
              <Typography>Upload Policy Copy</Typography>
              {file ? (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <AttachIcon />
                  <div style={{ display: "flex", width: "200px" }}>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        // width: "10px",
                      }}
                      variant="p"
                    >
                      {file?.name}
                    </Typography>
                  </div>
                  <RemoveIcon
                    sx={{ color: "red" }}
                    onClick={() => setfile(null)}
                    cursor={"pointer"}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleUploadPolicyCopy(file)}
                  >
                    {" "}
                    SUBMIT
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // gap: "8px",
                      background: "#0691FC",
                      padding: "12px 24px 12px 24px",
                      gap: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    <UploadIcon fill="white" />
                    <h2
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      <input
                        type="file"
                        onChange={(e) => {
                          setfile(e.target.files?.[0]);
                        }}
                        name="uploadfile"
                        id="img"
                        style={{ display: "none" }}
                      />
                      <label htmlFor="img" style={{ cursor: "pointer" }}>
                        Upload Policy Copy
                      </label>
                    </h2>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentLinkPending;
