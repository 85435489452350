import React, { useState } from "react";
import Heading from "../../components/generic/Heading/Heading";
import { Typography, Button } from "@mui/material";
import TextInput from "../../components/generic/Input";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getPinCodeCity, sendFillQuoteDetailsForm } from "../../store/api";
import { useParams } from "react-router-dom";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
import Home from "../../assets/icons/majesticons_home.svg";
import { getSalesQuoteDetailAPI } from "../../store/action/salesQuote";
import { useDispatch } from "react-redux";
import axios from "../../store/api/axios";

const SalesInspectionsDetails = () => {
  const { id } = useParams();
  const [city, SetCity] = useState(null);
  const dispatch = useDispatch();

  // Form validation schema
  const detailSchema = yup.object().shape({
    address: yup.string().required("Address is required"),
    pincode: yup
      .string()
      .matches(/^\d{6}$/, "PIN code is not valid")
      .required("Pin code is required"),
  });

  const handleToGetPinCode = async (pincode) => {
    if (pincode.length === 6) {
      try {
        const response = await axios.get(
          `${getPinCodeCity}?pincode=${pincode}`,
        );

        if (response?.data?.success) {
          SetCity(response.data.data);
        } else {
          SetCity(null);
        }
      } catch (error) {
        console.error("Error fetching city:", error);
        SetCity(null);
      }
    } else {
      SetCity(null);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(detailSchema),
    defaultValues: { address: "", pincode: "" },
  });

  const onSubmit = async (formData) => {
    const apiData = {
      quoteId: id,
      address: formData.address,
      pincode: formData.pincode,
    };

    try {
      const response = await axios.post(sendFillQuoteDetailsForm, apiData);
      if (response?.data?.success) {
        const quoteDetailsResponse = await dispatch(getSalesQuoteDetailAPI(id));
        if (quoteDetailsResponse?.data?.success) {
          reset();
          message.success(response?.data.msg);
        }
      } else {
        message.error(response?.data.msg);
      }
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <>
      <Typography
        component={"div"}
        sx={{
          display: "flex",
          marginTop: "20px",
          alignItems: "center",
          justifyContent: "start",
          gap: "10px",
        }}
      >
        <Heading text="Inspection Details" type="withborder" />
        <Typography
          component={"div"}
          sx={{
            backgroundColor: "#ff3e78",
            padding: "4px 16px",
            fontWeight: "500",
            color: "white",
            fontSize: "10px",
            borderRadius: "12px",
            textAlign: "center",
            marginTop: "15px",
          }}
        >
          Pending
        </Typography>
      </Typography>

      <Typography
        component={"div"}
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: "auto",
          borderRadius: "16px",
          boxShadow: "0px 1px 6px 0px #00000040",
          padding: "20px",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            component={"div"}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
              position: "relative",
            }}
          >
            {/* Address Field */}
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder="Enter Address"
                  icon={Home}
                  sx={{
                    "& input::placeholder": {
                      color: "#B3B3B3",
                      opacity: 1,
                      fontSize: "12px",
                      fontWeight: "600",
                    },
                  }}
                />
              )}
            />
            <Typography
              component={"span"}
              sx={{
                fontSize: "12px",
                color: "red",
                fontWeight: "500",
                top: "68px",
                left: "12px",
                position: "absolute",
              }}
            >
              {errors.address && (
                <div className="error_class">{errors.address.message}</div>
              )}
            </Typography>

            {/* Pincode Field */}
            <Controller
              name="pincode"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder="Enter Your area pincode"
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "").slice(0, 6);
                    field.onChange(value);
                    handleToGetPinCode(value);
                  }}
                  sx={{
                    "& input::placeholder": {
                      color: "#B3B3B3",
                      opacity: 1,
                      fontSize: "12px",
                      fontWeight: "600",
                    },
                  }}
                />
              )}
            />
            <Typography component={"span"}>
              {city && (
                <Typography
                  sx={{
                    position: "absolute",
                    color: "#0691FC",
                    width: "140px",
                    height: "23px",
                    borderRadius: "27px",
                    backgroundColor: "#E8F3FC",
                    top: "110px",
                    right: "28px",
                    fontSize: "11px",
                    textAlign: "center ",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="fill_details_pin_code"
                >
                  {`${city.city}, ${city.state}`}
                </Typography>
              )}
            </Typography>
            <Typography
              component={"span"}
              sx={{
                fontSize: "12px",
                color: "red",
                fontWeight: "500",
                bottom: "-8px",
                left: "12px",
                position: "absolute",
              }}
            >
              {errors.pincode && (
                <div className="error_class">{errors.pincode.message}</div>
              )}
            </Typography>
          </Typography>

          {/* Submit Button */}
          <Typography
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "15px",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                margin: "10px 15px",
                padding: "10px 23px",
                backgroundColor: "#0691fc",
                width: "max-content",
              }}
            >
              SUBMIT
            </Button>
          </Typography>
        </form>
      </Typography>
    </>
  );
};

export default SalesInspectionsDetails;
