import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Heading from "../../components/generic/Heading/Heading";
import SuccessModal from "../../components/generic/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { shareQuoteApprovalAPI } from "../../store/action/quote";
import DropDown from "../../components/generic/DropDown/DropDown";
import { quoteStatusList } from "../../helpers/enums";
import Addons from "../../components/QuoteComponent/Addons";
import { InsurerLogoMap } from "../../helpers/InsurerLogoMap";

const QuoteAcceptedApproval = () => {
  const data = useSelector((state) => state.quote.quoteDetails);
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(true);
  const [reason, setReason] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [declineData, setDeclineData] = useState(false);

  const handleReason = (selectedReason) => {
    setReason(selectedReason);
  };

  const handleAccept = (id) => {
    const data = {
      quoteRequestId: id,
      isQuoteApproved: isValid,
    };
    dispatch(shareQuoteApprovalAPI(data, setOpenSuccess));
  };

  const handleReject = (id) => {
    const data = {
      quoteRequestId: id,
      isQuoteApproved: isValid,
      quoteDeclineReason: reason,
    };
    dispatch(shareQuoteApprovalAPI(data, setOpenSuccess, setDeclineData));
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "0 30px 0 0",
      }}
    >
      <Heading text="Shared Quotes" type="withborder" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          marginTop: "16px",
        }}
      >
        {data?.data?.offlineQuotes?.map((i, index) => (
          <div
            key={`quote-${index}`}
            style={{
              display: "flex",
              borderRadius: "8px",
              boxShadow: "0px 2px 4px 0px #0000001A",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: "100%",
                background:
                  i?.quoteStatus === quoteStatusList.ACCEPTED ||
                    i?.quoteStatus === quoteStatusList.ACCEPTED_WITH_INSPECTION
                    ? "#E2F1E6"
                    : "#fff",
                padding: "12px 16px",
              }}
            >
              {i?.quoteStatus === quoteStatusList.ACCEPTED ||
                i?.quoteStatus === quoteStatusList.ACCEPTED_WITH_INSPECTION ? (
                <>
                  {openSuccess ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 12,
                          marginTop: 4,
                        }}
                      >
                        {
                          i?.insurerId && InsurerLogoMap[i?.insurerId] ?
                            <img src={InsurerLogoMap[i?.insurerId]?.Logo} alt="insurer" />
                            :

                            <Typography
                              sx={{ fontSize: 12, color: "#666666", fontWeight: 500 }}
                            >
                              {i?.insurerName}
                            </Typography>
                        }
                        <div
                          style={{
                            backgroundColor: "#09CE1D",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: "10px",
                              fontWeight: 500,
                            }}
                          >
                            Quote Accepted
                          </h3>
                        </div>
                      </div>
                      <SuccessModal
                        title={
                          declineData
                            ? "Quote Rejected Successfully!"
                            : "Quote Approved Successfully!"
                        }
                        // subTitle={declineData?"Same has been notified to partner":"You will be notified once partner accept/reject the quotes"}
                        // shareButton="Okay"
                        btnDisabled
                      />
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 12,
                        }}
                      >
                        {
                          i?.insurerId && InsurerLogoMap[i?.insurerId] ?
                            <img src={InsurerLogoMap[i?.insurerId]?.Logo} alt="insurer" />
                            :

                            <Typography
                              sx={{ fontSize: 12, color: "#666666", fontWeight: 500 }}
                            >
                              {i?.insurerName}
                            </Typography>
                        }
                        <div
                          style={{
                            backgroundColor: "#09CE1D",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: "10px",
                              fontWeight: 500,
                            }}
                          >
                            {i?.quoteStatusName}
                          </h3>
                        </div>
                      </div>

                      {isValid ? (
                        <Box display="flex" flexDirection="row" gap={1}>
                          <Button
                            onClick={() => handleAccept(i?.quoteRequestId)}
                            startIcon={<DoneIcon />}
                            sx={{
                              color: "#23963D",
                              fontSize: 12,
                              textTransform: "capitalize",
                            }}
                          >
                            Approved
                          </Button>
                          <Button
                            onClick={() => setIsValid(false)}
                            startIcon={<CloseIcon />}
                            sx={{
                              color: "#DF0000",
                              fontSize: 12,
                              textTransform: "capitalize",
                            }}
                          >
                            Rejected
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems={"center"}
                        >
                          <Box flexGrow={1} />
                          <DropDown
                            value={reason}
                            onChange={(e) => handleReason(e.target.value)}
                            placeholder="select reason"
                            data={["Reason 1", "Reason 2", "Reason 3"]}
                            size="small"
                            style={{
                              fontSize: 14,
                              border: "1px solid #8B8B8B",
                              width: "100%",
                              background: "#fff",
                              height: 34,
                              borderRadius: 8,
                              padding: "0 12px",
                            }}
                          />
                          <Button
                            variant="text"
                            onClick={() => handleReject(i?.quoteRequestId)}
                            disabled={!reason}
                            sx={{
                              color: "#DF0000",
                              mx: 2,
                              fontSize: 12,
                              minWidth: "unset",
                              textTransform: "capitalize",
                            }}
                          >
                            SUBMIT
                          </Button>

                          <Button onClick={() => setIsValid(true)}>EDIT</Button>
                        </Box>
                      )}
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 12,
                    }}
                  >
                    {
                      i?.insurerId && InsurerLogoMap[i?.insurerId] ?
                        <img src={InsurerLogoMap[i?.insurerId]?.Logo} alt="insurer" />
                        :

                        <Typography
                          sx={{ fontSize: 12, color: "#666666", fontWeight: 500 }}
                        >
                          {i?.insurerName}
                        </Typography>
                    }
                  </div>
                  <Link
                    to={i?.quoteUrl}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {" "}
                    <RemoveRedEyeOutlined />
                    <h2
                      style={{
                        color: "#666666",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      View Quote{" "}
                    </h2>
                  </Link>
                </Box>
              )}
            </div>
            {i?.addOns?.length > 0 && <Addons addOns={i?.addOns} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuoteAcceptedApproval;
