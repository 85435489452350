import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Stack, Typography } from "@mui/material";
import { AvatarColors } from "../../assets/constant";
import "./index.css";
const SalesManagerTable = ({ preIssuanceList, onRowClick }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F2F2F2", height: "42px" }}>
              {["Reportees", "Quotes", "Mappings"].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontWeight: "500",
                    lineHeight: "18px",
                    fontSize: "14px",
                    color: "#181818",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {preIssuanceList.map((data, index) => (
              <TableRow
                className="row-hover "
                key={index}
                sx={{
                  cursor: "pointer",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                onClick={() => onRowClick(data)}
              >
                <TableCell sx={{ height: "90px", position: "relative" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar
                      className="avatorEffect"
                      sx={{
                        bgcolor: AvatarColors(
                          data.employeeName || data.employeeLogo,
                        ), // Generate color based on name or logo
                        width: "48px",
                        height: "48px",
                        borderRadius: "24px",
                        border: "0px",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      {data.employeeLogo}
                    </Avatar>
                    <Typography
                      component="span"
                      sx={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: "#4FE61A",
                        borderRadius: "50%",
                        position: "absolute",
                        left: "34px",
                        bottom: "21px",
                      }}
                    ></Typography>
                    <div>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        {data.employeeName}
                      </Typography>
                      <br />
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "18px",
                          color: "#0691FC",
                        }}
                      >
                        {data.employeeeId}
                      </Typography>
                    </div>
                  </Stack>
                </TableCell>

                {/* Quotes Cell */}
                <TableCell>
                  <Typography
                    component="div"
                    sx={{
                      width: "230px",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "7px",
                    }}
                  >
                    {data.quotesData && data.quotesData.length > 0 ? (
                      data.quotesData.map((quote, idx) => (
                        <Typography
                          className="HoverKeyEffect"
                          key={idx}
                          component="span"
                          sx={{
                            backgroundColor: "#F2F2F2",
                            color: "#666666",
                            padding: "2px 4px",
                            borderRadius: "6px",
                            border: "1px solid #E6E6E6",
                            height: "22px",
                            textAlign: "center",
                            fontSize: "11px",
                            lineHeight: "16px",
                            fontWeight: "400",
                            marginBottom: "5px",
                          }}
                        >
                          {quote.key}
                          <Typography
                            component="span"
                            sx={{
                              fontSize: "12px",
                              lineHeight: "18px",
                              fontWeight: "500",
                              color: "#00223C",
                              marginLeft: "6px",
                            }}
                          >
                            {quote.value}
                          </Typography>
                        </Typography>
                      ))
                    ) : (
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "12px",
                          color: "#888",
                          fontStyle: "italic",
                          fontWeight: "500",
                        }}
                      >
                        No Quotes Found
                      </Typography>
                    )}
                  </Typography>
                </TableCell>

                {/* Mappings Cell */}
                <TableCell>
                  <Typography
                    component="div"
                    sx={{
                      width: "250px",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {data.mappingsData && data.mappingsData.length > 0 ? (
                      data.mappingsData.map((mapping, index) => (
                        <Typography
                          className="HoverKeyEffect"
                          key={index}
                          component="span"
                          sx={{
                            backgroundColor: "#F2F2F2",
                            padding: "2px 4px",
                            borderRadius: "6px",
                            border: "1px solid  #E6E6E6",
                            height: "22px",
                            textAlign: "center",
                            fontSize: "11px",
                            lineHeight: "16px",
                            fontWeight: "400",
                            color: "#666666",
                          }}
                        >
                          {mapping.key}
                          <Typography
                            component="span"
                            sx={{
                              fontSize: "12px",
                              lineHeight: "18px",
                              fontWeight: "500",
                              color: "#00223C",
                              marginLeft: "6px",
                            }}
                          >
                            {mapping.value}
                          </Typography>
                        </Typography>
                      ))
                    ) : (
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "12px",
                          color: "#888",
                          fontStyle: "italic",
                          fontWeight: "500",
                        }}
                      >
                        No Mapping Found
                      </Typography>
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SalesManagerTable;
