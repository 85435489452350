import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import {
  createFormDataWithFileAndJSON,
  kycDocFields,
  quoteDocumentsObj,
  uploadFileAllowedFormats,
} from "../../assets/constant";
import "./style.css";
import { ReactComponent as BinIcon } from "../../assets/icons/bin.svg";
import FileSelector from "../../components/generic/FileSelector";
import ChequeDetails from "./ChequeDetails";
import CustomButton from "../../components/Button/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import Heading from "../../components/generic/Heading/Heading";
import { salesPostKycDataURL } from "../../store/api";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
import axios from "../../store/api/axios";
import { getSalesQuoteDetailAPI } from "../../store/action/salesQuote";
const KycDocumentUpload = () => {
  const [chequeDetailsArray, setChequeDetailsArray] = useState([]);
  const [docs, setDocs] = useState([]);
  const { reuploadDocument, chequeDetails, header } = useSelector(
    (state) => state.salesQuote.quoteDetails.data,
  );
  const dispatch = useDispatch();
  const addChequeDetails = () => {
    setChequeDetailsArray([...chequeDetailsArray, {}]);
  };
  const removeCheque = (indexToRemove) => {
    setChequeDetailsArray(
      chequeDetailsArray.filter((_, i) => i !== indexToRemove),
    );
    const currentChequeDetails = getValues("chequeDetails");
    const updatedChequeDetails = currentChequeDetails.filter(
      (_, index) => index !== indexToRemove,
    );
    setValue("chequeDetails", updatedChequeDetails);
    const otherFormValues = getValues();

    // refresh the form to show the updated values
    reset({
      ...otherFormValues,
      chequeDetails: updatedChequeDetails,
    });
  };

  const isDocReupload = docs.some((item) => item.documentRejectReason);

  const isChequeReupload = chequeDetailsArray.some(
    (item) => item.chequeStatus && item.chequeStatus !== "VERIFIED",
  );

  const schema = yup.object().shape({
    ...docs.reduce((acc, item) => {
      acc[item.documentType] = yup
        .mixed()
        .required("Document is required")
        .test("fileType", "Invalid file format", (value) => {
          if (!value) return false;
          return uploadFileAllowedFormats.includes(value.type);
        })
        .test("fileSize", "The file is too large", (value) => {
          return value && value.size <= 5000000;
        });
      return acc;
    }, {}),
    // allow null check details
    chequeDetails: yup
      .array()
      .of(
        yup.object().shape({
          chequeNumber: yup.string().required("Cheque Number is required"),
          issueDate: yup.string().required("Cheque Date is required"),
          amount: yup.string().required("Cheque Amount is required"),
          bankName: yup.string().required("Cheque Bank is required"),
          chequeStatus: yup.string().optional().nullable(),
          chequeUrl: yup.string().optional().nullable(),
          // chequeFile is required if chequeStatus is not VERIFIED
          chequeFile: yup
            .mixed()
            .test({
              name: "conditionalValidation",
              test: function (value) {
                const chequeStatus = this.parent.chequeStatus;
                // If chequeStatus is not "VERIFIED" and chequeFile is empty, return false
                if (chequeStatus !== "VERIFIED" && !value) {
                  throw new yup.ValidationError(
                    "Cheque File is required",
                    value,
                    this.path,
                  );
                }
                return true;
              },
            })
            .nullable(),
        }),
      )
      .nullable(),
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      chequeDetails: [],
    },
  });
  const onSubmit = async (kycData) => {
    // const params = `quoteId=${currentQuote?.quoteId}`;
    let { chequeDetails: chequeToUpload, ...rest } = kycData;
    // add FILE as per index in the array and previousChequeNumber chequeDetails already have cheque
    let chequeData = [];

    for (let index = 0; index < chequeToUpload.length; index++) {
      let item = chequeToUpload[index];
      if (!item.chequeStatus || item.chequeStatus !== "VERIFIED") {
        let amount = item.amount.replace(/,/g, "");
        let newCheque = {
          chequeNumber: item.chequeNumber,
          bankName: item.bankName,
          issueDate: item.issueDate,
          amount: amount,
        };
        if (chequeDetails[index]?.chequeNumber)
          newCheque.previousChequeNumber = chequeDetails[index]?.chequeNumber;

        chequeData.push(newCheque);
      }
    }

    const finalData = {
      ...rest,
      quoteId: header.ticketId,
    };
    if (chequeData && chequeData.length > 0) {
      finalData.chequeDetailsList = chequeData;
    }
    if (
      chequeToUpload &&
      chequeToUpload[0] &&
      (chequeToUpload[0]?.chequeStatus !== "VERIFIED" ||
        !chequeToUpload[0]?.chequeStatus)
    ) {
      if (chequeToUpload[0]?.chequeFile)
        finalData.CHEQUE_ONE = chequeToUpload[0]?.chequeFile;
    }
    if (
      chequeToUpload &&
      chequeToUpload[1] &&
      (chequeToUpload[1]?.chequeStatus !== "VERIFIED" ||
        !chequeToUpload[1]?.chequeStatus)
    ) {
      if (chequeToUpload[1]?.chequeFile)
        finalData.CHEQUE_TWO = chequeToUpload[1]?.chequeFile;
    }
    let formData = createFormDataWithFileAndJSON(finalData);

    try {
      const response = await axios.post(salesPostKycDataURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response?.data?.success) {
        message.success(response?.data?.msg);
        // dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
        dispatch(getSalesQuoteDetailAPI(header.ticketId));
      } else {
        message.error(response?.data?.msg);
      }
    } catch (error) {
      message.error("Upload Document failed");
    }
  };

  useEffect(() => {
    if (reuploadDocument?.length > 0) {
      setDocs(reuploadDocument);
    } else if (chequeDetails?.length === 0) {
      setDocs(kycDocFields);
    }
  }, [reuploadDocument, chequeDetails]);

  useEffect(() => {
    if (chequeDetails) {
      setChequeDetailsArray(chequeDetails);
      setValue("chequeDetails", chequeDetails);
    }
  }, [chequeDetails, setValue]);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Heading text="Customer KYC Documents" type="withborder" />
        <Typography variant="body2" className="pending_status">
          {isDocReupload ? "Re-upload documents" : "Pending"}
        </Typography>
      </Box>

      <Box className="card_background" p={3} borderRadius={2} boxShadow={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            {docs?.map((item, index) => (
              <Box key={index} mb={2}>
                <Controller
                  name={item.documentType}
                  control={control}
                  defaultValue={undefined}
                  render={({ field }) => (
                    <Box className="doc_card" mb={2}>
                      <Box className="doc_name_content">
                        <Typography variant="body1" className="doc_name">
                          {quoteDocumentsObj[item?.documentType] ??
                            item?.documentType}
                        </Typography>
                      </Box>

                      {!field.value && item.documentRejectReason && (
                        <Box className="doc_status_content">
                          <Typography variant="body2" className="doc_status">
                            {item.documentRejectReason}
                          </Typography>
                        </Box>
                      )}

                      <Box className="doc_btn_content">
                        <FileSelector
                          id={item.documentType}
                          file={field.value}
                          field={field}
                          handleFileChange={(file) => field.onChange(file)}
                        />
                      </Box>
                    </Box>
                  )}
                />
                {errors[item.documentType] && (
                  <Typography
                    variant="body2"
                    className="error_class"
                    color="error"
                  >
                    {errors[item.documentType]?.message}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>

          <Box className="kyc_details_container" mt={3}>
            <Box className="section_name mb_12 mt_12">
              {chequeDetailsArray.length > 0 && "Cheque Details"}
            </Box>

            {!(isDocReupload || isChequeReupload) &&
              chequeDetailsArray.length < 2 && (
                <Box
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "18px",
                    textAlign: "right",
                    color: "#181818",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={addChequeDetails}
                >
                  <Typography variant="body2">
                    {chequeDetailsArray.length === 0
                      ? "Add cheque details (if any)"
                      : "Add one more cheque details (if any)"}
                  </Typography>
                </Box>
              )}

            {isChequeReupload && (
              <Typography variant="body2" className="pending_status">
                Re-upload documents
              </Typography>
            )}
          </Box>

          <Box mt={2}>
            {chequeDetailsArray?.map((item, index) => (
              <Box key={index} position="relative" mb={2}>
                <Controller
                  name={`chequeDetails[${index}]`}
                  control={control}
                  render={({ field }) => (
                    <Box>
                      <ChequeDetails
                        item={item}
                        field={field}
                        errors={errors}
                        index={index}
                        reset={reset}
                      />
                    </Box>
                  )}
                />
                {(!item.chequeStatus || item.chequeStatus === "") && (
                  <IconButton
                    onClick={() => removeCheque(index)}
                    style={{
                      position: "absolute",
                      top: "-20%",
                      left: "-6px",
                      background: "white",
                    }}
                  >
                    <BinIcon />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <CustomButton type="submit" className="doc_submit">
              SUBMIT
            </CustomButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default KycDocumentUpload;
