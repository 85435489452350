import { Box, CircularProgress, Stack } from "@mui/material";
import Search from "../../components/generic/Search/Search";
import Header from "../../components/generic/Header/Header";
import DropDown from "../../components/generic/DropDown/DropDown";
import { useCallback, useEffect, useState } from "react";
import Paginations from "../../components/generic/Pagination/Pagination.jsx";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import CustomTabing from "../../components/generic/Tabing/CustomTabing.jsx";
import { salesPartnerList } from "../../store/action/salesPartner.js";
import SalesPartnerTable from "./SalesPartnerTable.jsx";
import { fetchParterIdsList } from "../../store/action/auth.js";
import { debounce, formatDate } from "../../utils/utils";

export default function SalesPartners() {
  let searchParams = {};
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [selectedPartner, setSelectedPartner] = useState("");
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  // sales partnerList
  const { partnerList, partnerBucket, pagination, loading } = useSelector(
    (state) => state?.salesPartner,
  );
  const { partnerIds } = useSelector((state) => state.auth);

  // Debounce the search input
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      if (searchValue.length >= 3) {
        setDebouncedSearch(searchValue);
      } else {
        setDebouncedSearch("");
      }
    }, 300),
    [],
  );

  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [currentBucket, setcurrentBucket] = useState();
  const [debouncedSearch, setDebouncedSearch] = useState("");

  //  SEARCH LIST
  const handleSearch = (evt) => {
    const searchValue = evt.target.value;
    setSearch(searchValue);
    debounceSearch(searchValue);
  };
  const handleDateChange = (date) => {
    if (date && date.length > 0) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  const handleTabChange = (newValue) => {
    if (!loading) {
      setcurrentBucket(newValue);
      setCurrentPage(1);
    }
  };

  // page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (!partnerIds) {
      dispatch(fetchParterIdsList());
    }
  }, [partnerIds]);

  // Trigger API call to fetch the partner list for the selected bucket
  useEffect(() => {
    if (currentBucket) {
      searchParams["curBucket"] = currentBucket;
    }

    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (selectedPartner) {
      searchParams["partnerId"] = selectedPartner;
    }
    if (debouncedSearch) {
      searchParams["searchQuery"] = debouncedSearch;
    }

    if (filterByDate.startDate && filterByDate.endDate) {
      searchParams["startDate"] = formatDate(filterByDate.startDate);
      searchParams["endDate"] = formatDate(filterByDate.endDate);
    }

    dispatch(salesPartnerList(searchParams));
  }, [
    currentBucket,
    currentPage,
    selectedPartner,
    filterByDate,
    debouncedSearch,
  ]);

  return (
    <main className="main">
      <Header title="Partners " />
      <div className="partners-filters-item">
        <Search value={search} onChange={handleSearch} />

        <Stack direction="row" spacing={2} alignItems="center">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="label">Filters :</label>
          <DropDown
            value={selectedPartner}
            onChange={(e) =>
              setSelectedPartner(
                e.target.value === "No Status" ? "" : e.target.value,
              )
            }
            width={175}
            placeholder="Select Partner"
            data={partnerIds}
          />
          <div className="date-picker">
            <Flatpickr
              placeholder="Date Range"
              options={{
                dateFormat: "d-m-Y",
                maxDate: new Date(),
                mode: "range",
              }}
              className="flat_picker_date"
              style={{ width: "200px", height: "41px" }}
              value={
                filterByDate.startDate && filterByDate.endDate
                  ? [filterByDate.startDate, filterByDate.endDate]
                  : ""
              }
              onChange={handleDateChange}
            />
          </div>
        </Stack>
      </div>
      <CustomTabing
        value={partnerBucket?.curBucket}
        handleChange={handleTabChange}
        tabList={partnerBucket?.bucketList || []}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <SalesPartnerTable partnerList={partnerList} />
      )}
      <Paginations
        currentPage={currentPage}
        totalPage={pagination?.totalPage || 1}
        handlePageChange={handlePageChange}
      />
    </main>
  );
}
