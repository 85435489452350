import { Grid, Typography } from "@mui/material";
import React from "react";
import Document from "../../assets/icons/Document.svg";

const SalesSharedQuotes = () => {
  return (
    <>
      <Grid
        sx={{
          margin: " 30px auto",
          width: "616px",
          height: "246px",
          borderRadius: "16px",
          backgroundColor: "white ",
        }}
      >
        <Typography
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <Typography component={"div"} sx={{ marginTop: "30px" }}>
            <Typography component={"span"}>
              <img
                src={Document}
                alt="Document"
                width={"67px"}
                height={"78px"}
              />
            </Typography>
          </Typography>{" "}
          <Typography component={"div"} sx={{ textAlign: "center" }}>
            <Typography
              component={"span"}
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "27px",
                color: "black",
              }}
            >
              {" "}
              Documents Verified, Quotes pending!
            </Typography>
            <br />
            <Typography
              component={"span"}
              sx={{
                fontSize: "14px",
                color: "#666666",
                lineHeight: "21px",
                fontWeight: "400",
              }}
            >
              We are fetching the quotes from Insurers
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </>
  );
};

export default SalesSharedQuotes;
