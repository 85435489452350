import React from "react";
import "./FormInput.css";

const FormInput = ({
  field,
  icon,
  placeholder,
  isDisabled,
  defaultValue,
  handleChange,
  inputMode,
  onChange,
  ...props
}) => {
  const handleOnChange = (e) => {
    // if onChange is passed as a prop, call it else call field.onChange
    const fieldOnChange = onChange ? onChange : field.onChange;

    if (inputMode === "numeric") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        fieldOnChange(e);
      }
    } else if (inputMode === "tel") {
      // const re = /^[0-9\b]+$/; of length 10
      const re = /^[0-9\b]{0,10}$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        fieldOnChange(e);
      }
    }
    // decimal number
    else if (inputMode === "decimal") {
      const re = /^[0-9\b]+(\.[0-9]{0,4})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        fieldOnChange(e);
      }
    }
    //currency which is shown as a  comma seprated after every 3 digits
    else if (inputMode === "currency") {
      // const re = /^[0-9\b]+(\.[0-9]{0,2})?$/;
      // let val
      if (e.target.value === "") {
        fieldOnChange(e);
      } else {
        let s = e.target.value;
        s = s.replace(/,/g, "");
        let val = parseFloat(s);
        fieldOnChange({
          target: {
            value: val.toLocaleString("en-IN"),
            name: e.target.name,
          },
        });
      }
    } else {
      fieldOnChange(e);
    }
  };
  return (
    <div className="form_input_container">
      <div className="wrapper">
        {icon && <div className="icon">{icon}</div>}
        <input
          className={icon ? "input" : "input no_icon"}
          inputMode={inputMode || "text"}
          {...field}
          onChange={(value) => {
            if (handleChange) handleChange(value);
            handleOnChange(value);
          }}
          placeholder={placeholder}
          disabled={!!isDisabled}
          defaultValue={defaultValue}
          {...props}
        />
      </div>
    </div>
  );
};

export default FormInput;
