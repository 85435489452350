import { Button, Grid, Typography } from "@mui/material";
import Document from "../../assets/icons/Document.svg";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { uploadFileAllowedFormats } from "../../assets/constant";
import FileSelector from "../../components/generic/FileSelector";
import {
  getSalesQuoteDetailAPI,
  reUploadDocs,
} from "../../store/action/salesQuote";
import { useParams } from "react-router-dom";
import Heading from "../../components/generic/Heading/Heading";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";

const SalesReuploadDoc = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.salesQuote.quoteDetails);
  const reuploadDocument = data.data?.reuploadDocument || [];

  // Validation schema dynamically generated
  const schema = yup.object().shape(
    reuploadDocument?.reduce((acc, item) => {
      return {
        ...acc,
        [item.documentType]: yup
          .mixed()
          .required("Please select a file")
          .test("fileType", "Invalid file format", (value) => {
            if (!value) return false;
            const supportedFormats = uploadFileAllowedFormats;
            return supportedFormats.includes(value.type);
          })
          .test("fileSize", "The file is too large", (value) => {
            return value && value.size <= 5000000;
          }),
      };
    }, {}),
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData) => {
    const formDataWithFiles = new FormData();
    formDataWithFiles.append("quoteId", id);

    reuploadDocument.forEach((doc) => {
      const file = formData[doc.documentType];
      if (file) {
        formDataWithFiles.append(doc.documentType, file);
      }
    });

    try {
      // Dispatch the re-upload action
      const uploadResponse = await dispatch(reUploadDocs(formDataWithFiles));

      if (uploadResponse?.success) {
        // Fetch the updated quote details
        const quoteDetailsResponse = await dispatch(getSalesQuoteDetailAPI(id));

        if (quoteDetailsResponse?.data?.success) {
          reset();
        }
      }
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <>
      <Heading text="Requested Quotes" type="withborder" />
      <Grid
        sx={{
          margin: "30px auto",
          width: "100%",
          height: "auto",
          borderRadius: "16px",
          backgroundColor: "white",
        }}
      >
        <Typography
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <Typography component={"div"} sx={{ marginTop: "30px" }}>
            <Typography component={"span"}>
              <img
                src={Document}
                alt="Document"
                width={"67px"}
                height={"78px"}
              />
            </Typography>
          </Typography>
          <Typography component={"div"} sx={{ textAlign: "center" }}>
            <Typography
              component={"span"}
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "27px",
                color: "black",
              }}
            >
              No Quotes Shared Yet!
            </Typography>
            <br />
            <Typography
              component={"span"}
              sx={{
                fontSize: "14px",
                color: "#666666",
                lineHeight: "21px",
                fontWeight: "400",
              }}
            >
              Please re-Upload the documents as mentioned below
            </Typography>
          </Typography>
        </Typography>

        {/* Form */}
        <Typography component={"div"} sx={{ margin: "20px 30px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {reuploadDocument.map((doc, index) => (
              <Typography
                key={index}
                component={"div"}
                sx={{
                  backgroundColor: "#fafafa",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  margin: "20px 0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  position: "relative",
                  gap: "15px",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{ fontSize: "14px", color: "#666" }}
                >
                  {doc.documentName}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "white",
                    borderRadius: "27px",
                    alignItems: "center",
                    backgroundColor: "#ff3e78",
                    padding: "4px 16px",
                    textAlign: "center",
                    width: "max-content",
                  }}
                >
                  {doc.documentRejectReason}
                </Typography>

                <Controller
                  name={doc.documentType}
                  control={control}
                  render={({ field }) => (
                    <FileSelector
                      id={doc.documentType}
                      file={field.value}
                      field={field}
                      handleFileChange={(file) => field.onChange(file)}
                    />
                  )}
                />

                <Typography
                  component={"div"}
                  sx={{ position: "absolute", top: "50px" }}
                >
                  {errors[doc.documentType] && (
                    <Typography
                      sx={{ fontSize: "12px", color: "red" }}
                      className="error_class text-start mb-3 mt-0"
                    >
                      {errors[doc.documentType]?.message}
                    </Typography>
                  )}
                </Typography>
              </Typography>
            ))}

            <Typography
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  margin: "10px 15px",
                  padding: "10px 23px",
                  backgroundColor: "#0691fc",
                  width: "  max-content",
                }}
              >
                SUBMIT
              </Button>
            </Typography>
          </form>
        </Typography>
      </Grid>
    </>
  );
};

export default SalesReuploadDoc;
