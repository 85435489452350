import React from "react";
import { TextField, InputAdornment } from "@mui/material";

const FormInput = ({
  field,
  icon,
  placeholder,
  isDisabled,
  defaultValue,
  handleChange,
  inputMode,
  onChange,
  maxLength,
  ...props
}) => {
  const handleOnChange = (e) => {
    const fieldOnChange = onChange ? onChange : field.onChange;

    if (maxLength && e.target.value.length > maxLength) {
      return;
    }
    if (inputMode === "alpha") {
      const re = /^[A-Za-z\s]+$/; //
      if (e.target.value === "" || re.test(e.target.value)) {
        fieldOnChange(e);
      }
    } else if (inputMode === "numeric") {
      const re = /^(?!0\d)\d+$/;

      if (e.target.value === "" || re.test(e.target.value)) {
        fieldOnChange(e);
      } else {
        // remove all other characters except numbers
        let val = e.target.value.replace(/[^0-9]/g, "");
        fieldOnChange({
          target: {
            value: val,
            name: e.target.name,
          },
        });
      }
      // fieldOnChange(e);
    } else if (inputMode === "tel") {
      const re = /^[0-9\b]{0,10}$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        fieldOnChange(e);
      }
    } else if (inputMode === "decimal") {
      const re = /^[0-9\b]+(\.[0-9]{0,4})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        fieldOnChange(e);
      }
    } else if (inputMode === "currency") {
      if (e.target.value === "") {
        fieldOnChange(e);
      } else {
        let s = e.target.value;
        s = s.replace(/,/g, "");
        if (isNaN(s)) return;
        let val = parseFloat(s);
        if (isNaN(val)) return;
        fieldOnChange({
          target: {
            value: val.toLocaleString("en-IN"),
            name: e.target.name,
          },
        });
      }
    } else {
      fieldOnChange(e);
    }
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder={placeholder}
      {...field}
      value={field?.value || defaultValue}
      inputMode={inputMode || "text"}
      disabled={isDisabled}
      InputProps={{
        startAdornment: icon ? (
          <InputAdornment position="start">{icon}</InputAdornment>
        ) : null,
      }}
      onChange={(e) => {
        if (handleChange) handleChange(e);
        handleOnChange(e);
      }}
      sx={{ backgroundColor: "white" }}
      {...props}
    />
  );
};

export default FormInput;
