import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React from "react";

const NewAutoComplete = ({
  value,
  options,
  placeholder,
  isMulti,
  onChange,
  isSelectAllAllowed = true,
  ...rest
}) => {
  const selectAllOption = {
    label: "Select All",
    value: "selectAll",
  };

  const multiOptions = [];
  if (isSelectAllAllowed) {
    multiOptions.push(selectAllOption);
  }
  multiOptions.push(...options);

  const newOptions = isMulti ? multiOptions : options;
  const isAllSelected = isMulti && value?.length === options.length;

  const handleChange = (event, newValue) => {
    if (isMulti) {
      newValue = newValue || [];
      if (newValue.some((option) => option.value === "selectAll")) {
        if (isAllSelected) {
          onChange([]);
        } else {
          onChange(options.map((option) => option.value));
        }
      } else {
        onChange(newValue.map((option) => option.value));
      }
    } else {
      onChange(newValue ? newValue.value : "");
    }
  };

  return (
    <Autocomplete
      sx={{
        // width: "220px",
        // height: "80px",
        backgroundColor: "#fff",
        "& .MuiAutocomplete-inputRoot": {
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          fontWeight: "500",
        },
      }}
      {...rest}
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      options={newOptions}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions={false}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {isMulti && (
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={option.value === "selectAll" ? isAllSelected : selected}
            />
          )}
          {option.label}
        </li>
      )}
      renderTags={(tagValue, getTagProps) => {
        if (isMulti) {
          if (tagValue.length > 1) {
            return [
              <React.Fragment key={tagValue[0].value}>
                <Chip
                  size="small"
                  variant="outlined"
                  key={tagValue[0].value}
                  label={tagValue[0].label.substring(0, 4)}
                  {...getTagProps({ index: 0 })}
                />
                +{tagValue.length - 1}
              </React.Fragment>,
            ];
          } else {
            return tagValue.map((option, index) => (
              <Chip
                size="small"
                variant="outlined"
                key={option.value}
                label={option.label.substring(0, 4)}
                {...getTagProps({ index })}
              />
            ));
          }
        } else {
          return tagValue.map((option, index) => (
            <Chip
              size="small"
              variant="outlined"
              key={option.value}
              label={option.label.substring(0, 4)}
              {...getTagProps({ index })}
            />
          ));
        }
      }}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
      value={
        isMulti
          ? value && Array.isArray(value)
            ? options.filter((option) => value.includes(option.value))
            : []
          : value
            ? options.find((option) => option.value == value)
            : undefined
      }
    />
  );
};

export default NewAutoComplete;
