import React, { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { Box, Grid, Typography, Button, Divider } from "@mui/material";
import FormInput from "../../components/generic/FormInput/FormInput";
import { policyTypeOptions } from "../../assets/constant";
import NewAutoComplete from "../generic/NewAutoComplete/NewAutoComplete";
import CustomField from "../generic/CustomSwitch/CustomField";
import { ReactComponent as AddOnIcon } from "../../assets/icons/AddOnIcon.svg";

export const isVehicleTypeOptions = {
  newVehicle: [policyTypeOptions.COMPREHENSIVE],
  oldVehicle: [
    policyTypeOptions.COMPREHENSIVE,
    policyTypeOptions.THIRD_PARTY,
    policyTypeOptions.SAOD,
  ],
};

const RequestDetails = ({
  errors,
  control,
  vehicleType,
  setVehicleType,
  resetField,
  isNewVehicle,
}) => {
  const responseData = useSelector((state) => state?.insurers);
  const insurerList = responseData?.insurerList || [];
  const addOnsList = responseData?.addOnsList || [];

  const insuranceOptions = (insurerList || []).map((item) => ({
    label: item,
    value: item,
  }));

  const addOnOptions = (addOnsList || []).map((item) => ({
    label: item,
    value: item,
  }));

  const [showAddOns, setShowAddOns] = useState(false);

  const toggleAddOns = () => {
    setShowAddOns((prevState) => !prevState);
  };

  const handleToResetAddOns = () => {
    setShowAddOns(false);
    resetField("addOns", []);
  };

  const handleVehicleTypeChange = (selectedOption) => {
    setVehicleType(selectedOption);
    setShowAddOns(false);
    resetField("selectInsurer", []);
    resetField("idv", "");
    resetField("addOns", []);
    resetField("pypExpiryDate", "");
    resetField("ncb", "");
  };


  return (
    <Fragment>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Divider>
          <Typography
            variant="body2"
            sx={{
              color: "#757575", // Custom text color (gray)
              fontWeight: "300", // Normal weight
              fontSize: "12px", // Text size similar to your image
              textTransform: "uppercase", // Make the text uppercase
            }}
          >
            Request Details
          </Typography>
        </Divider>

        <Box>
          {/* Implement CustomField with DoneIcon */}
          <CustomField
            value={vehicleType}
            setvalue={handleVehicleTypeChange}
            options={
              isNewVehicle
                ? isVehicleTypeOptions.newVehicle
                : isVehicleTypeOptions.oldVehicle
            }
          />
        </Box>


        <Grid container spacing={2}>
          <Grid
            item
            xs={
              vehicleType[0] === policyTypeOptions.COMPREHENSIVE ||
              vehicleType[0] === policyTypeOptions.SAOD
                ? 8
                : 12
            }
          >
            <Controller
              control={control}
              name="selectInsurer"
              defaultValue={null}
              render={({ field }) => {
                const { value, ...rest } = field;
                return (
                  <NewAutoComplete
                    {...rest}
                    dataTestId="select_insurer"
                    isMulti={true}
                    options={insuranceOptions}
                    isSearchable
                    onChange={(value) => {
                      if (value.length <= 3) {
                        field.onChange(value);
                      }
                    }}
                    value={value}
                    isClearable
                    isSelectAllAllowed={false}
                    placeholder="Select Insurers"
                  />
                );
              }}
            />
            {errors.selectInsurer && (
              <Typography className="error_class">
                {errors.selectInsurer.message}
              </Typography>
            )}
          </Grid>
          

          {vehicleType[0] === policyTypeOptions.COMPREHENSIVE ||
          vehicleType[0] === policyTypeOptions.SAOD ? (
            <Grid item xs={4}>
              <Controller
                control={control}
                name="idv"
                render={({ field }) => (
                  <FormInput
                    field={field}
                    placeholder="Enter IDV"
                    type=""
                    inputMode="currency"
                  />
                )}
              />
              {errors.idv && (
                <Typography className="error_class">
                  {errors.idv.message}
                </Typography>
              )}
            </Grid>
          ) : null}

          {!showAddOns && (
            <Grid item xs={12} textAlign="right" > 
              <Button sx={{textTransform:"none"}} onClick={toggleAddOns}> <AddOnIcon /> Add Ons</Button>
            </Grid>
          )}

          {showAddOns && (
            <Fragment>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name="addOns"
                  render={({ field }) => (
                    <NewAutoComplete
                      {...field}
                      options={addOnOptions}
                      isSearchable
                      isClearable
                      isMulti
                      placeholder={"Select..."}
                      className="select_dropdown"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} textAlign="left">
                <Button sx={{textTransform:"none"}} color="error" onClick={handleToResetAddOns}>
                  Remove Add Ons
                </Button>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default RequestDetails;
