import React from "react";
import DialogBox from "../generic/DialogBox";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import FileSelector from "../generic/FileSelector";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../Button/CustomButton/CustomButton";
import * as yup from "yup";
import {
  creataMultipleDocUploadPayload,
  quoteDocumentsObj,
  uploadFileAllowedFormats,
} from "../../assets/constant";
import "./index.css";
import axios from "../../store/api/axios";
import { uploadSalesDocsPOST } from "../../store/api";
import { getSalesMappingListAPI } from "../../store/action/salesMapping";
import { message } from "../generic/SnakeAlert/SnakeAlert";

const MappingUploadDoc = ({ open, handleClose, mappingId }) => {
  const { uploadDocsList } = useSelector((state) => state.salesMapping);
  const dispatch = useDispatch();
  const schema = yup.object().shape(
    uploadDocsList?.reduce((acc, item) => {
      return {
        ...acc,
        [item.documentType]: yup
          .mixed()
          .required("Please select a file")
          .test("fileType", "Invalid file format", (value) => {
            if (!value) return true; 
            const supportedFormats = uploadFileAllowedFormats;
            return supportedFormats.includes(value.type);
          })
          .test("fileSize", "The file is too large", (value) => {
            if (!value) return true; 
            return value.size <= 5000000; // 5MB maximum file size
          }),
      };
    }, {}),
  );
  
  const onSubmit = async (data) => {
    const finalData = { ...data, mappingId: mappingId };
    const formData = creataMultipleDocUploadPayload(finalData);
    const response = await axios.post(uploadSalesDocsPOST, formData);
    if (response.status === 200 && response.data && response.data.success) {
      reset();
      dispatch(getSalesMappingListAPI());
      message.success("Documents uploaded successfully");
      handleClose();
    } else {
      message.error("Failed to upload documents");
    }
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  return (
    <div>
      <DialogBox
        width={"50%"}
        open={open}
        setOpen={handleClose}
        children={
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              marginX={"auto"}
              gap={"1rem"}
              alignItems={"center"}
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              height={"100%"}
            >
              {uploadDocsList?.map((item, index) => (
                <div key={index} className="w-full">
                  <Controller
                    name={item.documentType}
                    control={control}
                    defaultValue={undefined}
                    render={({ field }) => (
                      <div style={{ width: "100%" }}>
                        <div className="doc_card">
                          <div className="doc_name_content">
                            <p className="doc_name mb-0">
                              {quoteDocumentsObj[item?.documentType] ??
                                item?.documentName}
                            </p>
                          </div>
                          {!field.value && (
                            <div className="doc_status_content">
                              <p className="doc_status mb-0">
                                {item.documentRejectReason}
                              </p>
                            </div>
                          )}
                          <div className="doc_btn_content">
                            <FileSelector
                              id={item.documentType}
                              file={field.value}
                              field={field}
                              handleFileChange={(file) => field.onChange(file)}
                            />
                          </div>
                        </div>
                        {errors[item.documentType] && (
                          <p className="error_class text-start mb-3 mt-0">
                            {errors[item.documentType]?.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              ))}
              <div className="w-full align-items-center justify-content-end">
                <CustomButton
                  type="submit"
                  alignCenter
                  className="doc_submit"
                />
              </div>
            </Box>
          </form>
        }
        title={"Upload Documents"}
      />
    </div>
  );
};

export default MappingUploadDoc;
