import React, { Fragment, useEffect } from "react";
import { Controller } from "react-hook-form";
import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  Divider,
} from "@mui/material";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import CustomField from "../generic/CustomSwitch/CustomField";
import { policyTypeOptions } from "../../assets/constant";
import { CalendarMonth } from "@mui/icons-material";
export const isPypAvailableOptions = ["Yes", "No"];



const PreviousPolicy = ({
  errors,
  control,
  previousPolicyFlag,
  setPreviousPolicyFlag,
  claimPolicyFlag,
  setClaimPolicyFlag,
  vehicleType,
  resetField,
}) => {
  useEffect(() => {
    if (previousPolicyFlag[0] === "No") {
      resetField("pypExpiryDate", "");
      resetField("ncb", "");
      setClaimPolicyFlag(["No"]);
    } else {
      resetField("ncb", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousPolicyFlag]);

  useEffect(() => {
    if (claimPolicyFlag[0] === "Yes") {
      resetField("ncb", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimPolicyFlag]);

  return (
    <Fragment>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Divider>
          <Typography
            variant="body2"
            sx={{
              color: "#757575", // Custom text color (gray)
              fontWeight: "300", // Normal weight
              fontSize: "12px", // Text size similar to your image
              textTransform: "uppercase", // Make the text uppercase
            }}
          >
            Previous Year Policy Details
          </Typography>
        </Divider>

        <Grid container alignItems="center" justifyContent="space-between">
          <Grid
            item
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"64%"}
          >
            <Box>
              <Typography variant="body1" className="me-5 fw-bold width_178" sx={{fontWeight:"600",color:'black' }}>
                Do you have a PYP?
              </Typography>
            </Box>
            <Box>
              <CustomField
                value={previousPolicyFlag}
                setvalue={setPreviousPolicyFlag}
                options={isPypAvailableOptions}
              />
            </Box>
          </Grid>
          {previousPolicyFlag[0] === "Yes" && (
            <Grid item xs={4}>
              <Controller
                control={control}
                name="pypExpiryDate"
                render={({ field }) => (
                  <Box
                    className="date-picker"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      padding: "0",
                    }}
                  >
                    <Flatpickr
                      {...field}
                      placeholder="Expiry Date"
                      className="form_date_picker"
                      style={{ width: "100%" }}
                    />
                    <IconButton>
                      {" "}
                      <CalendarMonth color="#0691FC" />{" "}
                    </IconButton>
                  </Box>
                )}
              />
              {errors.pypExpiryDate && (
                <Typography className="error_class">
                  {errors.pypExpiryDate.message}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>

        {previousPolicyFlag[0] === "Yes" &&
          (vehicleType[0] === policyTypeOptions.COMPREHENSIVE ||
            vehicleType[0] === policyTypeOptions.SAOD) && (
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid
                item
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"64%"}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "600", color: "black" }}
                  >
                    Claim in PYP?
                  </Typography>
                </Box>
                <Box>
                  <CustomField
                    value={claimPolicyFlag}
                    setvalue={setClaimPolicyFlag}
                    options={isPypAvailableOptions}
                  />
                </Box>
              </Grid>
              {claimPolicyFlag[0] === "No" && (
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="ncb"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        placeholder="Enter NCB %"
                        fullWidth
                      />
                    )}
                  />
                  {errors.ncb && (
                    <Typography className="error_class">
                      {errors.ncb.message}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          )}
      </Box>
    </Fragment>
  );
};

export default PreviousPolicy;
