import React from "react";
import { ReactComponent as CaseClosedIcon } from "../../assets/icons/case_closed.svg";
import { quoteRequestStateList } from "../../helpers/enums";

const CaseClosedModal = ({ quoteState }) => {
  return (
    <div
      style={{
        width: "calc(100% - 30px)",
        borderRadius: "16px",
        backgroundColor: "#fff",
        padding: "20px 0 20px 0",
        marginRight: "30px",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <CaseClosedIcon />
      <h2
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
          fontWeight: 500,
          color: "#000",
          // height: "40vh",
        }}
      >
        {quoteState === quoteRequestStateList.CASE_CLOSED
          ? "Case has been closed."
          : "No data found"}
      </h2>
    </div>
  );
};

export default CaseClosedModal;
