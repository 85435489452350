import React from "react";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";
import { ReactComponent as DoneIcon } from "../../../assets/icons/DoneIcon.svg";


// CustomField component with Material-UI Chips
const CustomField = ({ options, setvalue, value, ismulti }) => {
  // For single select
  const handleSingleChecked = (id) => setvalue([id]);

  // For multi-select
  const handleClick = (id) => {
    if (value?.includes(id)) {
      setvalue(value?.filter((item) => item !== id));
    } else {
      setvalue([...value, id]);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "16px" }}>
      {options?.map((item, index) => {
        const isSelected = value?.includes(item);

        return (
          <Chip
            key={index}
            label={item}
            clickable
            onClick={() =>
              ismulti ? handleClick(item) : handleSingleChecked(item)
            }
            icon={isSelected ? <DoneIcon /> : null} 
            color={isSelected ? "primary" : "default"}
            style={{ marginBottom: "10px" }}
          />
        );
      })}
    </Box>
  );
};

export default CustomField;
