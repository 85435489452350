import React from "react";
import Header from "../../components/generic/Header/Header";
import { CircularProgress, Stack, Typography } from "@mui/material";
import DropDown from "../../components/generic/DropDown/DropDown";
import { useEffect, useState } from "react";
import { fetchParterIdsList } from "../../store/action/auth.js";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/plugins/monthSelect/style.css";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index";
import DashboardToggle from "./DashboardData/DashboardToggle.jsx";
import TopPerformingTable from "./DashboardData/TopPerformingTable.jsx";
import { getSalesDashboardDataAPI } from "../../store/action/dashboard.js";
import DashboardChart from "./DashboardData/DashboardChart.jsx";
import DashboardBookingCard from "./DashboardData/DashboardBookingCard.jsx";
import DashboardMappingCard from "./DashboardData/DashboardMappingCard.jsx";
import DashboardQuoteCard from "./DashboardData/DashboardQuoteCard.jsx";

const Dashboard = () => {
  let searchParams = {};
  const dispatch = useDispatch();
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [filterByDate, setFilterByDate] = useState();
  const [dateType, setDateType] = useState("MAPPING");
  const { partnerIds } = useSelector((state) => state.auth);

  const onboardingDetails = useSelector(
    (state) => state?.salesDashboard?.onboardingDetails,
  );

  const isLoading = useSelector((state) => state?.salesDashboard?.loading);
  const mappingDetails = useSelector(
    (state) => state?.salesDashboard?.mappingDetails,
  );

  const quoteDetails = useSelector(
    (state) => state?.salesDashboard?.quoteDetails,
  );

  const bookingDetails = useSelector(
    (state) => state?.salesDashboard?.bookingDetails,
  );

  const topPerformingPartners = useSelector(
    (state) => state?.salesDashboard?.topPerformingPartners,
  );
  const handleDateChange = (selectedDates) => {
    const selectedDate = selectedDates[0];
    setFilterByDate(selectedDate);
  };

  const handleToggleChange = (e) => {
    setDateType(e.target.value);
  };

  useEffect(() => {
    if (!partnerIds) {
      dispatch(fetchParterIdsList());
    }
  }, [partnerIds]);

  useEffect(() => {
    if (selectedPartner) {
      searchParams["partnerId"] = selectedPartner;
    }
    if (filterByDate) {
      searchParams["month"] = filterByDate.getMonth().toString();
      searchParams["year"] = filterByDate.getFullYear().toString();
    }
    if (dateType) {
      searchParams["dateType"] = dateType;
    }
    dispatch(getSalesDashboardDataAPI(searchParams));
  }, [selectedPartner, filterByDate, dateType]);

  return (
    <div>
      <Header title={"Dashboard"} />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full height of the viewport
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className="main-div" style={{ width: "100%" }}>
          <div
            className="toggle-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="select-partner">
              <Stack direction="row" spacing={2} alignItems="center">
                <DropDown
                  value={selectedPartner}
                  onChange={(e) => setSelectedPartner(e.target.value)}
                  width={185}
                  color={"grey"}
                  placeholder="Select Partner"
                  data={partnerIds}
                />
              </Stack>
            </div>

            <div className="toggle-button">
              <DashboardToggle value={dateType} onChange={handleToggleChange} />
            </div>

            <div className="date-picker" style={{ marginRight: "8px" }}>
              <Flatpickr
                placeholder=" Month - Year"
                options={{
                  dateFormat: "m-Y",
                  maxDate: new Date(),
                  mode: "single",
                  // allowInput: true,
                  disableMobile: true,
                  plugins: [
                    new monthSelectPlugin({
                      shorthand: true, // Displays the month in shorthand (e.g., "Jan", "Feb")
                      dateFormat: "F Y", // How the date is displayed in the input
                      altFormat: "F Y", // How the date is shown to the user
                    }),
                  ],
                }}
                className="flat_picker_date"
                style={{ width: "100%", height: "41px" }}
                onChange={handleDateChange}
                value={filterByDate}
              />
            </div>
          </div>
          <div
            className="card-container"
            style={{
              height: "172px",
              display: "flex",
              direction: "row",
              width: "100%",
              gap: "2%",
              alignItems: "center ",
              marginTop: "30px",
            }}
          >
            <div className="booking-card" style={{ flexBasis: "32.5%" }}>
              <DashboardBookingCard bookingDetails={bookingDetails} />
            </div>
            <div className="mapping-card" style={{ flexBasis: "32.5%" }}>
              <DashboardMappingCard mappingDetails={mappingDetails} />
            </div>
            <div className="qoute-card" style={{ flexBasis: "32.5%" }}>
              <DashboardQuoteCard quoteDetails={quoteDetails} />
            </div>
          </div>
          <br />
          <div>
            <div
              className="top=performing-table"
              style={{
                // marginTop: "2px",
                display: "flex",
                justifyContent: "space-between",
                gap: "2%",
                width: "100%",
              }}
            >
              <Typography
                component={"div"}
                sx={{ flexBasis: "67%", marginTop: "17px" }}
              >
                <TopPerformingTable
                  topPerformingPartners={topPerformingPartners}
                />
              </Typography>
              <Typography component={"div"} sx={{ flexBasis: "32.5%" }}>
                <DashboardChart onboardingDetails={onboardingDetails} />
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
