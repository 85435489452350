import React, { useEffect, useState } from "react";
import Header from "../../components/generic/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Stack, Typography } from "@mui/material";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/plugins/monthSelect/style.css";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index";
import SalesManagerPartnerTable from "./SalesManagerPartnerTable";
import { salesPartnerManagerListAction } from "../../store/action/salesManagerPartner";
import Paginations from "../../components/generic/Pagination/Pagination";
import EmployeeBreadcrumbs from "../../components/EmployeeBreadcrumbs/EmployeeBreadcrumbs";

const ManagerPartner = () => {
  const dispatch = useDispatch();
  const {
    salesManagerPartnersList,
    loading,
    pagination,
    hierarchyList,
    hierarchy,
  } = useSelector((state) => state?.salesPartnerManager);
  const [filterByDate, setFilterByDate] = useState();
  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [selectedHierarchy, setSelectedHierarchy] = useState([]);

  const handleDateChange = (selectedDates) => {
    const selectedDate = selectedDates[0];
    setFilterByDate(selectedDate);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowClick = (employee) => {
    setSelectedHierarchy([{ employeeId: employee.employeeeId }]);
  };

  const handleBreadcrumbClick = (employee) => {
    setSelectedHierarchy([{ employeeId: employee.employeeId }]);
  };
  useEffect(() => {
    let searchParams = {};
    if (filterByDate) {
      searchParams["month"] = filterByDate.getMonth().toString();
      searchParams["year"] = filterByDate.getFullYear().toString();
    }
    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (hierarchy) {
      searchParams["hierarchy"] = hierarchy;
    }

    if (selectedHierarchy.length > 0) {
      searchParams["curManagerId"] = selectedHierarchy[0].employeeId; // Use employeeId from selectedHierarchy
    }
    dispatch(salesPartnerManagerListAction(searchParams));
  }, [filterByDate, currentPage, selectedHierarchy]);
  return (
    <>
      <Header title="Partners" />
      <Typography component={"div"} sx={{ paddingBottom: "20px" }}>
        <EmployeeBreadcrumbs
          hierarchyList={hierarchyList}
          selectedHierarchy={selectedHierarchy}
          handleBreadcrumbClick={handleBreadcrumbClick}
        />
      </Typography>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className="list-wrapper">
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              justifyContent="end"
              margin="5px 10px"
            >
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                // justifyContent="space-between"
              >
                <label htmlFor="partner-dropdown" className="label">
                  Filters :
                </label>

                <div className="date-picker">
                  <Flatpickr
                    placeholder=" Month - Year"
                    options={{
                      dateFormat: "m-Y",
                      maxDate: new Date(),
                      mode: "single",
                      // allowInput: true,
                      disableMobile: true,
                      plugins: [
                        new monthSelectPlugin({
                          shorthand: true,
                          dateFormat: "F Y",
                          altFormat: "F Y",
                        }),
                      ],
                    }}
                    className="flat_picker_date"
                    style={{ width: "100%", height: "45px" }}
                    onChange={handleDateChange}
                    value={filterByDate}
                  />
                </div>
              </Stack>
            </Stack>
          </Typography>
          <Typography component={"div"} sx={{ marginTop: "15px" }}>
            <SalesManagerPartnerTable
              salesManagerPartnersList={salesManagerPartnersList}
              onRowClick={handleRowClick}
            />
          </Typography>
        </div>
      )}
      <Paginations
        currentPage={currentPage}
        totalPage={pagination?.totalPage || 1}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default ManagerPartner;
