import React from "react";
import Upload from "../../../assets/images/RaiseRequest/upload.svg";
import { ReactComponent as AttachFileIcon } from "../../../assets/icons/attachIcon.svg";
import { ReactComponent as BinIcon } from "../../../assets/icons/bin.svg";
import "./index.css";
const FileSelector = ({
  id,
  file,
  field,
  handleFileChange,
  fileTypes = ["pdf", "png", "jpeg", "jpg"],
  fileNameToDisplay = "",
  style = {},
}) => {
  const handleButtonClick = () => {
    // Trigger the hidden file input when the custom button is clicked
    document.getElementById(id).click();
  };
  let acceptFiles = fileTypes.map((type) => `.${type}`).join(", ");
  return (
    <div className="file_selector_container" style={style}>
      {!file ? (
        <div className="file_upload_btn_container">
          <input
            type="file"
            id={id} // Unique ID for Invoice_COPY
            style={{ display: "none" }}
            accept={acceptFiles}
            onChange={(e) => {
              if (field) field.onChange(e.target.files[0]);
              else handleFileChange(e.target.files[0]);
            }} // Pass the corresponding input ID}}
          />
          <button
            type="button"
            onClick={handleButtonClick} // Pass the corresponding input ID
            className="file_upload_doc_field_btn"
          >
            <img src={Upload} alt="upload" />
            Upload
          </button>
        </div>
      ) : (
        <div className="file_name_container">
          <AttachFileIcon className="file_icon" width="28px" height="24px" />
          <span className="file_name">
            {fileNameToDisplay || file.name || "File Attached"}
          </span>
          <div
            className="remove_file_btn"
            role="button"
            tabIndex={0}
            onClick={() => handleFileChange(null)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleFileChange(null);
              }
            }}
          >
            <BinIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileSelector;
